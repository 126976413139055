// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bV_kw";
export var caseStudyBackground__bgColor = "bV_ks";
export var caseStudyBackground__lineColor = "bV_kt";
export var caseStudyHead__imageAbsolute = "bV_kq";
export var caseStudyHead__imageWrapper = "bV_kp";
export var caseStudyProjectsSection = "bV_kx";
export var caseStudyQuote__bgRing = "bV_kr";
export var caseStudyTech = "bV_kF";
export var caseStudyVideo = "bV_kS";
export var caseStudyVideo__ring = "bV_kG";
export var caseStudy__bgDark = "bV_km";
export var caseStudy__bgLight = "bV_kl";