import { Link } from 'gatsby'
import { StaticImage, getImage } from 'gatsby-plugin-image'

import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import SubtractBlock from '~/components/shared/SubtractBlock'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'
import CaseStudyVideoCase from '~/views/CaseStudies/components/CaseStudyVideo'

import * as containerStyles from './Babel.module.scss'
import useBabelStaticQuery from './useBabelStaticQuery'

const quotes = {
  be: {
    name: 'Serhii',
    position: 'Tech Lead & Rails expert at Codica',
  },
}

const previewAfricarTitle = 'Multi-Vendor Vehicle Marketplace'
const previewDVTitle = 'Online Collaboration Marketplace'

const CaseStudiesBabel = () => {
  const query = useBabelStaticQuery()

  const banner = getImage(fileToImageLikeData(query.banner))
  const bannerBicycle = getImage(fileToImageLikeData(query.bicycle))
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )
  const videoCaseImage = getImage(fileToImageLikeData(query.videoCaseImage))

  return (
    <MainLayout isTransparent scrollTop>
      <CaseStudyHead
        title="Insurance Progressive Web Application for Babel Cover"
        maxWidthTitle="550px"
        subtitle={
          <>
            Learn how Codica helped Babel Cover create an intuitive insurance
            progressive web app (PWA) for bicycle riders and save costs on
            <Link
              to="/services/mobile-app-development/"
              className="ml5"
              key="subtitleMobileDevKey"
            >
              mobile app development
            </Link>
            .
          </>
        }
        image={banner}
        classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
        imageTitle="Insurance PWA case study"
        imageAlt="Insurance Progressive Web Application case study"
        imageAbsolute={bannerBicycle}
        classNameImageAbsolute={containerStyles.caseStudyHead__imageAbsolute}
        domain="Insurance"
        location="Dublin, Ireland"
        timeline="June 2019 - February 2020"
        services={
          <>
            Prototyping,
            <Link to="/services/ui-ux-design/" className="ml5" key="designKey">
              UX/UI design services
            </Link>
            , Front-end and Back-end development,
            <Link
              to="/services/progressive-web-apps-development/"
              className="ml5"
              key="pwaDevKey"
            >
              PWA development services
            </Link>
          </>
        }
        team="1 Project Manager, 2 UX/UI Designers, 8 Software Developers, 1 QA Engineer"
        technologies="Ruby on Rails, PostgreSQL, React, Redux, AWS"
      />

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudyBackground__bgColor}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTextLeft="Babel Cover is an early stage insurance technology startup ushering in a sustainable, transparent, and rewards linked insurance ecosystem. The company creates transparent personal insurance products that incentivize users to build loyal risk-conscious profiles."
        bgTextRight="The company has set a grand yet realizable vision to democratize insurance and bring consumers closer to the source of risk capital thereby building more resilient, transparent and cost-effective inter-connected financial products."
        sectionBusiness={[
          {
            label:
              'Build a fast-loading and user-friendly mobile application for the insurance industry, which would enable users to: quickly and easily purchase an insurance policy and report an incident right from their mobile phones.',
          },
          {
            label:
              'Create a customer loyalty program by providing a cashback (called ‘rewards’) in case there were no incidents during the insurance period. The purpose is to encourage bicycle riders to drive carefully and see after their property.',
          },
          {
            label:
              'Provide users with an ultra-secure data storage and payment processing, including private e-wallets that hold customers’ rewards. Make stored data accessible only to users of this web app for insurance.',
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Built a Progressive Web App which allowed Babel Cover to significantly cut costs on mobile app development and launch faster, at the same time providing its end-users with an enhanced mobile experience.',
          },
          {
            label:
              'Integrated Blockchain helped to build an insurance web app with safe payments processing. This integration made the PWA delivered a great web solution for the insurance domain.',
          },
          {
            label:
              'The application created has an effective and easy-to-manage administration panel, allowing to control the data and update the conditions when necessary.',
          },
          {
            label:
              'Embedded a complex logic and high accuracy of all the insurance calculations depending on the chosen scenarios. This step was essential to build an insurance web application that meets compliance regulations.',
          },
          {
            label:
              'The Progressive Web App delivered is ready for scaling by adding new products in the future.',
          },
        ]}
      />

      <CaseStudyQuote
        quote={[
          {
            text: '“Babel Cover is a revolutionary product in the insurance domain, and I’m happy that we had a chance to contribute to its success. Building a mobile application for the insurance industry can be a complicated and costly process, and choosing the PWA technology instead was a great decision.',
          },
          {
            text: 'Speaking about some of the technical solutions, we used the Amazon Web Services infrastructure to integrate with Blockchain technology and provide scalable, reliable, and secure data storage for this insurance progressive web app.',
          },
          {
            text: 'We also cached data from ChainLocker to boost the insurance web application’s performance and increase the page load speed. Docker helped us to simplify the development process, deploy the source code, and launch the insurance PWA faster.”',
          },
        ]}
        photo={
          <StaticImage
            src="../../../../assets/images/avatars/Sergey.png"
            alt={`${quotes.be.name}, ${quotes.be.position} at Codica`}
            title={`${quotes.be.name}, ${quotes.be.position}`}
            width={80}
            height={80}
          />
        }
        bgRing={containerStyles.caseStudyQuote__bgRing}
        name={quotes.be.name}
        position={quotes.be.position}
      />

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDark}
        title="Do you need a reliable team to build a PWA?"
        description="We apply best practices to help you save costs and launch quickly."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          image={solutionDeliveredOne}
          title="Progressive Web App"
          description="Created an insurance web app that is easy-to-use and fast-loading. It allows customers to use the app even with low-end mobile devices, poor network connection, or in an offline mode. This way, users can easily purchase an insurance policy and report an incident wherever they are just using a mobile phone with an insurance web app installed. It makes a PWA a first place to go, when in trouble."
          alt="Insurance Progressive Web App for Babel Cover"
          imageTitle="Insurance PWA for Babel Cover"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="UX and UI design"
          description="Built a responsive insurance web application with intuitive UX, as well as visually-appealing UI design according to the company’s identity. The user interface allows clients to select the required insurance options easily and helps them fill in the required information in this insurance progressive web app."
          alt="UX and UI design for insurance PWA"
          imageTitle="UI/UX design for insurance Progressive Web Application"
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="Blockchain (ChainLocker) integration"
          description="Used Blockchain and integrated ChainLocker into the insurance web application to provide users with an ultra-secure data storage including private e-wallets that hold customers’ rewards. Only if some problems with the funds’ withdrawal occur, then the company can get access to the private data of users and fix the issue. This integration makes this insurance PWA data security compliant and safe to use."
          alt="Babel Cover PWA blockchain (ChainLocker) integration"
          imageTitle="Blockchain (Chainlocker) integration for Babel Cover"
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredFour}
          title="Insurance calculations logic"
          description="Embedded a complex logic and high accuracy of all the insurance calculations depending on the chosen scenarios. Also, we included a high scalability perspective to add other insurance products. It allowed us to create an insurance web app that will easily adapt to larger demand and grow in the future."
          alt="Insurance calculations logic for progressive web application"
          imageTitle="Complex insurance calculations logic for PWA"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="Customer loyalty program"
          description="Implemented a customer loyalty program by providing a cashback (called ‘rewards’) in case there were no incidents during the insurance period. This feature fully subscribes to the philosophy behind this insurance Progressive Web Application."
          alt="PWA for Babel cover: customer loyalty program"
          imageTitle="Customer loyalty program in PWA for Babel Cover"
        />
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredSix}
          title="Claims management"
          description="Created an effective and easy-to-use Claims management functionality to control the current and past claims and submit or remove them if necessary. Here, you can see the status of your claims and view the final compensation for each incident. Also, this tab allows users to see the timeline of their claims."
          alt="Claims management in insurance PWA"
          imageTitle="Claims management for insurance progressive web app"
        />
      </div>

      <div className={containerStyles.caseStudyVideo}>
        <LazyHydrate whenVisible>
          <CaseStudyVideoCase
            title="How it works"
            description="In this video you can see how the progressive web app works. A user can quickly purchase bicycle insurance by filling in the required details and choosing the cover options."
            ringsClass={containerStyles.caseStudyVideo__ring}
            image={videoCaseImage}
            videoLink="https://www.youtube.com/embed/P-L5Z2k-dZw"
          />
        </LazyHydrate>
      </div>

      <div className={containerStyles.caseStudyTech}>
        <CaseStudyTech
          technologies={[
            { label: 'Ruby on Rails', icon: 'ror' },
            { label: 'PostgreSQL', icon: 'sql' },
            { label: 'React', icon: 'react' },
            { label: 'Redux', icon: 'redux' },
            { label: 'JWT', icon: 'jwt' },
            { label: 'Administrate', icon: 'administrate' },
            { label: 'RSpec', icon: 'rspec' },
            { label: 'Material UI', icon: 'material' },
            { label: 'Sidekiq', icon: 'sidekiq' },
            { label: 'Redis', icon: 'redis' },
          ]}
          integrations={[
            { label: 'ChainLocker', icon: 'chainlocker' },
            { label: 'AWS', icon: 'aws' },
            { label: 'Gitlab CI', icon: 'gitlab' },
            { label: 'Sentry.io', icon: 'sentry' },
            { label: 'Apcopay', icon: 'apcopay' },
          ]}
        />
        <div className="d-none d-lg-block">
          <SubtractBlock />
        </div>
      </div>

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s build a great custom product together!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewAfricarTitle}
              color="africarColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/africar.png"
                  alt={`Case study: ${previewAfricarTitle} | Codica`}
                  title={`Case study: ${previewAfricarTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                  width={350}
                />
              }
              url="multi-vendor-vehicle-marketplace"
            />
            <CaseStudyPreview
              title={previewDVTitle}
              color="dvColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/dv.png"
                  alt={`Case study: ${previewDVTitle} | Codica`}
                  title={`Case study: ${previewDVTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                />
              }
              url="collaboration-marketplace-and-smart-contract-system"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesBabel
